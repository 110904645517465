import React from "react";
import "./titlePage.css";

function TitlePage({ title }) {
  return (
    <div className="container-fluid p-0">
      <div className="title-box d-flex justify-content-center align-items-end fw-bold">
        <h1 className="mb-5 text-white">{title}</h1>
      </div>
    </div>
  );
}

export default TitlePage;