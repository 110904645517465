import React from "react";
import { Virtual, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./carrousel.css";

function Carrousel() {
  const datas = [
    {
      id: 1,
      titre: "La facilité de souscription SwitchCall",
      altImage: "icône de stylo",
      titreImage: "icones_stylo.png",
      description:
        "En quelques clics seulement, sélectionnez le forfait qui vous correspond et recevez votre carte SIM en un temps record. L'activation s'effectue automatiquement, et voilà, c'est terminé ! Une assistance aussi efficace que pratique. SwitchCall s'occupe de la résiliation de votre contrat et du transfert de votre numéro.",
    },
    {
      id: 2,
      titre: "Pourquoi choisir la qualité du réseau Bouygues Telecom ?",
      altImage: "icône de réseaux",
      titreImage: "icones_reseau.png",
      description:
        "SwitchCall vous propose un forfait mobile haut de gamme avec une couverture exceptionnelle garantie par le réseau Bouygues Telecom. Profitez d'une réception optimale, que vous soyez en ville, à la campagne, à la montagne ou en bord de mer, que ce soit à la maison ou en déplacement grâce à notre réseau mobile 4G, qui couvre 99% de la population française. En prime, notre réseau 5G ultra-rapide et puissant vous offre une expérience utilisateur sans précédent.",
    },
    {
      id: 3,
      titre: "Une assistance aussi efficace que pratique",
      altImage: "icône de conversation",
      titreImage: "icones_conv.png",
      description:
        "Si vous avez des questions à poser ou si vous avez besoin d'assistance, nous sommes là pour vous fournir des réponses et des conseils. Vous pouvez compter sur notre disponibilité, que ce soit via le chat, en personne dans nos boutiques, par téléphone ou en vous connectant avec l'un de nos conseillers personnels.",
    },
    {
      id: 4,
      titre: "Documents à fournir lors d'une souscription",
      altImage: "icône d'un contrat",
      titreImage: "./icones_contrat.png",
      description:
        "Chez SwitchCall, vous avez la chance de découvrir notre offre exceptionnelle : un forfait sur mesure à prix avantageux, comprenant des SMS, MMS et appels illimités. Vous pouvez aisément le personnaliser selon vos besoins spécifiques, vous garantissant ainsi une expérience téléphonique adaptée et abordable.",
    },
    {
      id: 5,
      titre: "Forfait sur mesure à votre disposition",
      altImage: "icône de téléphone",
      titreImage: "icones_tel.png",
      description:
        "SwitchCall vous propose un forfait mobile haut de gamme avec une couverture exceptionnelle garantie par le réseau Bouygues Telecom. Profitez d'une réception optimale, que vous soyez en ville, à la campagne, à la montagne ou en bord de mer, que ce soit à la maison ou en déplacement grâce à notre réseau mobile 4G, qui couvre 99% de la population française. En prime, notre réseau 5G ultra-rapide et puissant vous offre une expérience utilisateur sans précédent.",
    },
    {
      id: 6,
      titre: "Comment profiter de la 5G avec SwitchCall ?",
      altImage: "icône 5G",
      titreImage: "icones_5G.png",
      description:
        "Pour profiter de la 5G avec SwitchCall, il est important de vérifier si votre téléphone est compatible avec cette technologie. Si c'est le cas, il vous suffit de souscrire à un forfait mobile 5G. Si vous avez des questions sur la 5G ou si vous souhaitez vérifier la compatibilité de votre téléphone, n'hésitez pas à contacter notre service client.",
    },
  ];

  return (
    <main className="d-flex flex-column backgroundI w-100 pb-4">
      <h2 className="text-center text-white mt-5 display-1">
        Pourquoi nous choisir ?
      </h2>
      <Swiper
        modules={[Virtual, Navigation, Pagination]}
        slidesPerView={1}
        centeredSlides={true}
        loop={true}
        spaceBetween={30}
        navigation={true}
        virtual
        style={{ height: "500px" }}
        className="carrousel"
        breakpoints={{
          1500: {
            slidesPerView: 3,
          },
          886: {
            slidesPerView: 2,
          },
        }}
      >
        {datas.map((slideContent, index) => (
          <SwiperSlide
            className="carrousel_fen rounded-5 text-center bg-white d-flex justify-content-center align-items-center"
            key={slideContent}
            virtualIndex={index}
          >
            <div key={slideContent.id} className="h-100 containerCarousel">
              <div className="mt-4 overley d-flex flex-column align-items-center justify-content-between">
                <div className="d-bloc px-2">
                  <h3 className="overlay_titre">{slideContent.titre}</h3>
                </div>
                <img
                  className="overlay_img"
                  src={"/icones/" + slideContent.titreImage}
                  alt={slideContent.altImage}
                />
                <p className="fs-6 text-start m-5"> {slideContent.description}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </main>
  );
}

export default Carrousel;
