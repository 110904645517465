import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import MainContent from "../MainContent/MainContent";
import Carrousel from "../Carrousel/Carrousel";
import TextBtn from "../TextBtn/Text_btn";

function Home() {
  return (
    <main className="d-flex flex-column align-items-center w-100">
      <MainContent />
      <Carrousel />
      <TextBtn/>
    </main>
  );
}

export default Home;
