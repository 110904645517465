import React from "react";
import "./text_btn.css";

function Text_btn() {
  return (
    <div>
      <div className="font rounded-4 d-flex justify-content-center p-4">
        <p className="fs-3 text-white my-3">
          Découvrez la liberté de la connectivité avec nos forfaits mobiles :
          performances optimales, tarifs imbattables et service client dévoué
          vous attendent ! Naviguez, communiquez et vivez sans limites grâce à
          nos offres conçues pour vous. Faites le choix d'une expérience mobile
          inégalée, propulsée par la 5G, où vous pouvez profiter d'un débit
          ultra-rapide pour télécharger en un instant vos contenus préférés et
          jouer en ligne en toute fluidité. La 5G permet également une
          réactivité sans précédent, ouvrant la voie à une expérience
          utilisateur encore plus agréable et immersive.
        </p>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <a href="https://www.chezswitch.fr/forfait-mobile/#/" target="_blank" rel="noreferrer">
          <button id="souscrire" className="button-ligne d-inline-block bg-light fw-bold rounded-3 px-5 py-3">Souscrire en ligne</button>
        </a>
      </div>
    </div>
  );
}

export default Text_btn;
