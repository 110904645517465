import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import ampoule from "../../Images/amp-btn.png";
import phone from "../../Images/tel-btn.png"
import "./mainContent.css";

function MainContent() {
  return (
    <div className="backgroundImage w-100">
      <div className="container ms-5 ps-5">
        <h1 className="titre mb-4">
          Plus qu'un forfait,
          <br/> une connexion à l'innovation.
        </h1>
        <p className="fs-3 mb-4">
          Découvrez la liberté de la connectivité avec nos forfaits mobiles :
          performances optimales, tarifs imbattables et service client dévoué
          vous attendent !
        </p>
        <div className="boutonD w-75 d-flex m-auto m-xxl-0 justify-content-between align-items-center">
          <a href="tel:0189471956">
            <button className="button-contacte d-inline-block bg-light p-3 rounded-3 text-dark border-2 fs-5">
              <img src={phone} className="icone_btn  me-2" alt='icône de téléphone' />
              Contactez-nous au 01 89 47 19 56
            </button>
          </a>
          <a href="https://www.chezswitch.fr/forfait-mobile/#/" target="_blank" rel="noreferrer">
            <button className="buttonContent d-inline-block bg-light p-3 px-5 rounded-3 text-dark border-2 fs-5">
              <img src={ampoule} className="icone_btn me-5"  alt='icône ampoule'/>
              Souscrire en ligne
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default MainContent