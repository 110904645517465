import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";
import logo from "../../Images/logo.png";
import logoSwitch from "../../Images/logoSwitch.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <header className="Navbar bg-white text-center position-fixed w-100 top-0">
      <nav>
        <div className="head-Navbar d-flex align-items-center justify-content-between">
          <div>
            <Link to="/">
              <img src={logo} className="logo" alt="Logo ligne mobile"/>
            </Link>
          </div>
          <div className="d-flex">
            <a href="tel:0189471956">
              <button className="button d-inline-block p-3 px-5 fw-bold bg-light rounded-3">Souscrire au 01 89 47 19 56</button>
            </a>
            <div>
              <a href="https://www.chezswitch.fr/#/" target="_blank" rel="noreferrer">
                <img src={logoSwitch} className="logo-switch" alt="Logo de chez switch"/>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;